import * as React from "react"
import { css } from "@emotion/react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { FaRegCopy } from "react-icons/fa"
import { Helmet } from "react-helmet"

export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>
        {data.site.siteMetadata.company.name}
        {data.site.siteMetadata.company.type}
      </title>
    </Helmet>
    <div>
      <h3
        css={css`
          border-left: 4px solid #333;
          padding-left: 0.5rem;
        `}
      >
        会社概要
      </h3>
      <table
        css={css`
          margin-left: 1rem;
        `}
      >
        <tr>
          <th>会社名</th>
          <td>
            {data.site.siteMetadata.company.name}
            {data.site.siteMetadata.company.type}
          </td>
        </tr>
        <tr>
          <th>所在地</th>
          <td>{data.site.siteMetadata.company.address}</td>
        </tr>
        <tr>
          <th>設立</th>
          <td>{data.site.siteMetadata.company.establishment}</td>
        </tr>
        <tr>
          <th>代表社員</th>
          <td>{data.site.siteMetadata.company.ceo}</td>
        </tr>
        <tr>
          <th>お問い合わせ</th>
          <td>
            {data.site.siteMetadata.email}
            <CopyToClipboard text={data.site.siteMetadata.email}>
              <button
                css={css`
                  padding: 0;
                  margin: 0;
                  margin-left: 0.5rem;
                  background: none;
                  border: 0;
                  line-height: 0;
                  overflow: visible;
                  cursor: pointer;
                `}
              >
                <FaRegCopy />
              </button>
            </CopyToClipboard>
          </td>
        </tr>
      </table>
    </div>
    <div
      css={css`
        margin-top: 2rem;
      `}
    >
      <h3
        css={css`
          border-left: 4px solid #333;
          padding-left: 0.5rem;
        `}
      >
        経営理念
      </h3>
      <div
        css={css`
          margin-left: 1rem;
        `}
      >
        <p>
          Web技術を用いて効率化し、人々の豊かな生活の実現に貢献していきます。
        </p>
      </div>
    </div>
    <div
      css={css`
        margin-top: 2rem;
      `}
    >
      <h3
        css={css`
          border-left: 4px solid #333;
          padding-left: 0.5rem;
        `}
      >
        経営方針
      </h3>
      <div
        css={css`
          margin-left: 1rem;
        `}
      >
        <p>
          顧客を第一に考えることに徹します。顧客に寄り添い行動することで、より良いものをカタチにしていきます。
        </p>
        <p>
          貪欲に技術を獲得し、創意工夫をすることで価値を生み出していきます。
        </p>
        <p>
          上記を長期的な視点で行うことで、当社を長期的な発展へとつなげてまいります。
        </p>
      </div>
    </div>
    <div
      css={css`
        margin-top: 2rem;
      `}
    >
      <h3
        css={css`
          border-left: 4px solid #333;
          padding-left: 0.5rem;
        `}
      >
        事業内容
      </h3>
      <div
        css={css`
          margin-left: 1rem;
        `}
      >
        <h4>・ソフトウェア開発事業</h4>
        <div
          css={css`
            display: flex;
          `}
        >
          <p
            css={css`
              margin-left: 1rem;
            `}
          >
            Webアプリケーションの開発を顧客のニーズに応じて行います。
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        company {
          name
          type
          ceo
          address
          establishment
        }
        email
      }
    }
  }
`
